import React from "react";
// website
export const LOCATE_US = React.lazy(() =>
  import("../components/container/website/Locateus")
);
export const Borrowers = React.lazy(() =>
  import("../components/container/website/Borrowers")
);

export const LoanApply = React.lazy(() =>
  import("../components/container/website/ApplyLoan")
);
export const Demo = React.lazy(() =>
  import("../components/container/website/demo")
);
export const HOME_LOAN = React.lazy(() =>
  import("../components/container/website/HomeLoan")
);
export const LAP_LOAN = React.lazy(() =>
  import("../components/container/website/LapLoan")
);
export const PERSONAL_LOAN = React.lazy(() =>
  import("../components/container/website/PersonalLoan")
);
export const BUSINESS_LOAN = React.lazy(() =>
  import("../components/container/website/BusinessLoan")
);
export const OVERDRAFT_LOAN = React.lazy(() =>
  import("../components/container/website/OverDraftLoan")
);
export const SHORT_TERM_LOAN = React.lazy(() =>
  import("../components/container/website/ShortTermLoan")
);
export const SOLUTIONS = React.lazy(() =>
  import("../components/container/website/solution")
);
export const Market_place = React.lazy(() =>
  import("../components/container/website/Products/MarketplaceNew")
);
export const WEBSITE_TRADE_FINANCE = React.lazy(() =>
  import("../components/container/website/Products/Tradefinance")
);
export const WEBSITE_LETTER_OF_CREDIT = React.lazy(() =>
  import("../components/container/website/Products/LetterofCredit")
);
export const WEBSITE_BANK_GUARANTEE = React.lazy(() =>
  import("../components/container/website/Products/BankGuarantee")
);
export const WEBSITE_Bill_DISCOUNTING = React.lazy(() =>
  import("../components/container/website/Products/BillDiscounting")
);
export const WEBSITE_PROJECT_APPROVAL = React.lazy(() =>
  import("../components/container/website/Products/projectapproval")
);
export const WEBSITE_PROJECT_FINANCING = React.lazy(() =>
  import("../components/container/website/Products/ProjectFinancing")
);
export const WEBSITE_B2C = React.lazy(() =>
  import("../components/container/website/Products/B2CFinance")
);

export const WebsitePrivacyPolicy = React.lazy(() =>
  import("../components/container/website/privacyPolicy")
);
export const WebsiteLendersPage = React.lazy(() =>
  import("../components/container/website/Lenders")
);
export const WebsiteCommunityPage = React.lazy(() =>
  import("../components/container/website/Community")
);
export const WebsiteMerchantPage = React.lazy(() =>
  import("../components/container/website/Merchant")
);
export const WebsiteAboutPage = React.lazy(() =>
  import("../components/container/website/About")
);
export const WebsiteSupport = React.lazy(() =>
  import("../components/presentational/Website/support")
);
export const WebsiteHomePage = React.lazy(() =>
  import("../components/container/website/Home")
);
export const WebsiteContactPage = React.lazy(() =>
  import("../components/container/website/Contact")
);
export const DigitalOnboarding = React.lazy(() =>
  import("../components/container/website/DigitalOnboarding")
);
export const LoanOrgination = React.lazy(() =>
  import("../components/container/website/loanOrigination")
);
export const FraudPrevention = React.lazy(() =>
  import("../components/container/website/fraudPrevention")
);
export const UnderWritting = React.lazy(() =>
  import("../components/container/website/AutomatedUnderWritting")
);
export const Loanservicing = React.lazy(() =>
  import("../components/container/website/LoanServincing")
);
export const Riskmanagemnet = React.lazy(() =>
  import("../components/container/website/RiskManagement")
);
export const FINTECH_PAGE = React.lazy(() =>
  import("../components/container/website/We Serve/fintech")
);
export const MICRO_PAGE = React.lazy(() =>
  import("../components/container/website/We Serve/microfinancing")
);
export const NBFC_PAGE = React.lazy(() =>
  import("../components/container/website/We Serve/nbfc")
);
// Amit
export const NEO_BANKING = React.lazy(() =>
  import("../components/container/website/We Serve/neobanking")
);
export const SOLE_LENDERS = React.lazy(() =>
  import("../components/container/website/We Serve/solelenders")
);

export const DEBT_COLLECTION = React.lazy(() =>
  import("../components/container/website/DebtCollection")
);
export const LOAN_MANAGEMENT_SYSTEM = React.lazy(() =>
  import("../components/container/website/LoanManagementSystem")
);
export const WORKFLOW_AUTOMATIONS = React.lazy(() =>
  import("../components/container/website/WorkflowAutomations")
);
export const ESIGN_AND_EMANDATE_SOLUTIONS = React.lazy(() =>
  import("../components/container/website/eSignAndeMandate")
);
export const BUSINESS_INTELLIGENCE_SUITE = React.lazy(() =>
  import("../components/container/website/BusinessIntelligenceSuite")
);
export const CORRESPONDENT_LENDING_SOLUTIONS = React.lazy(() =>
  import("../components/container/website/CorrespondentLendingSolutions")
);

export const CAPiTIVE_FINANCING = React.lazy(() =>
  import("../components/container/website/Starups/captiveFinance")
);

export const INVOICE_FINANCING = React.lazy(() =>
  import("../components/container/website/Starups/NonCommercial/invoiceFinance")
);
export const TRAVEL_AGENCIES = React.lazy(() =>
  import("../components/container/website/Starups/NonCommercial/TravelAgencies")
);
export const POS_LENDING = React.lazy(() =>
  import("../components/container/website/Starups/NonCommercial/POSLending")
);
export const RESTURANTS_HOTEL = React.lazy(() =>
  import(
    "../components/container/website/Starups/NonCommercial/RestaurantsHotel"
  )
);
export const RETAIL = React.lazy(() =>
  import("../components/container/website/Starups/NonCommercial/Retail")
);
export const MEDICAL = React.lazy(() =>
  import("../components/container/website/Starups/NonCommercial/Medical")
);
export const BNPL = React.lazy(() =>
  import("../components/container/website/Starups/NonCommercial/BNPL")
);
export const ECOMMERCE_FINANCING = React.lazy(() =>
  import(
    "../components/container/website/Starups/NonCommercial/eCommerceFinancing"
  )
);
export const AUTO_FINANCING = React.lazy(() =>
  import("../components/container/website/Starups/NonCommercial/AutoFinancing")
);
export const EDUCATION_LOAN = React.lazy(() =>
  import("../components/container/website/Starups/NonCommercial/EducationLoan")
);
export const OVERDRAFT_FINANCING = React.lazy(() =>
  import(
    "../components/container/website/Starups/NonCommercial/OverdraftFinancing"
  )
);

export const COMMUNICATION_VIA_WHATSAPP = React.lazy(() =>
  import(
    "../components/container/website/FEATURED SOLUTIONS/CommunicationViaWhatsapp"
  )
);
export const IVR_INTEGRATION = React.lazy(() =>
  import("../components/container/website/FEATURED SOLUTIONS/IVRIntegration")
);
export const PRODUCT_CONFIGURATION = React.lazy(() =>
  import(
    "../components/container/website/FEATURED SOLUTIONS/ProductConfiguration"
  )
);
export const LEAD_MANAGEMENT = React.lazy(() =>
  import("../components/container/website/FEATURED SOLUTIONS/LeadManagement")
);
export const WEBSITE_VEHICLE = React.lazy(() =>
  import("../components/container/website/Vehicleloan")
);
export const WEBSITE_BANKPAGE = React.lazy(() =>
  import("../components/container/website/bankPages")
);
export const WEBSITE_BANKPAGE_DETAILS = React.lazy(() =>
  import("../components/container/website/bankDetailsPages")
);
export const WEBSITE_PRODUCT_DETAILS = React.lazy(() =>
  import("../components/container/website/bankknowMorePage")
);
export const WEBSITE_BUSINESS = React.lazy(() =>
  import("../components/container/website/Products/buisness")
);
export const WEBSITE_RETAIL_NEW = React.lazy(() =>
  import("../components/container/website/Products/reatail")
);
export const WEBSITE_INFRA = React.lazy(() =>
  import("../components/container/website/Products/infra")
);
export const WEBSITE_MOBILITY = React.lazy(() =>
  import("../components/container/website/Products/CredhubMobility")
);
export const WEBSITE_INVOICEFINANCE = React.lazy(() =>
  import("../components/container/website/Products/InvoiceFinance")
);
export const WEBSITE_INVOICEFACTORING = React.lazy(() =>
  import("../components/container/website/Products/InvoiceFactoring")
);
export const WEBSITE_INVOICEDISCOUNTING = React.lazy(() =>
  import("../components/container/website/Products/InvoiceDiscounting")
);
export const WEBSITE_EVLOAN = React.lazy(() =>
  import("../components/container/website/EvLoan")
);
export const WEBSITE_WORK_CAPITAL_LOAN = React.lazy(() =>
  import("../components/container/website/Products/Workingcapitalloan")
);
export const WEBSITE_PLANT_MACHINARY = React.lazy(() =>
  import("../components/container/website/Products/PlantAndMachinary")
);

// End
export const STARTUP_PAGE = React.lazy(() =>
  import("../components/container/website/Starups/commercialStartups")
);
export const MERCHANT_CASH = React.lazy(() =>
  import("../components/container/website/Starups/merchantCash")
);
export const PEER_TO_PEER = React.lazy(() =>
  import("../components/container/website/Starups/peerToPeer")
);
export const EKYC = React.lazy(() =>
  import("../components/container/website/FEATURED SOLUTIONS/EKYC")
);
export const CIBIL = React.lazy(() =>
  import("../components/presentational/Website/FEATURED SOLUTIONS/CIBIL")
);
export const WEBSITE_BANK_STATEMENT_ANALYSIS = React.lazy(() =>
  import(
    "../components/presentational/Website/FEATURED SOLUTIONS/Bankstatementanalysis"
  )
);
export const EsignPage = React.lazy(() =>
  import("../components/container/EsignAndEnach/Esign.container")
);
export const EnachPage = React.lazy(() =>
  import("../components/container/EsignAndEnach/Enach.container")
);

// super admin dashboard
export const SuperAdminLoginPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/DahsboardLayout/Login")
);
export const SuperAdminDashboardLayoutPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/DahsboardLayout/Dashboard.container"
  )
);
export const SuperAdminDashboardPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Dashboard/Dashboard")
);
export const SuperAdminProductPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Product/product")
);
export const SuperAdminAddProductPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Product/addProduct")
);
export const SuperAdminModulePage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Module/module")
);
export const SuperAdminAddModulePage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Module/addModule")
);
export const SuperAdminRolesPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Roles/roles")
);
export const SuperAdminAddRolesPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Roles/addRoles")
);
export const SuperAdminLicencePage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Licence/licence")
);
export const SuperAdminAddLicencePage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Licence/addLicence")
);
export const SuperAdminUserManagementPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/UserManagement/userManagement"
  )
);
export const SuperAdminAddUserPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/UserManagement/addUser")
);
export const SuperAdminGroupManagementPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/GroupManagement/GroupManagement"
  )
);
export const SuperAdminWorkflowPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Workflow/workflow")
);
export const SuperAdminAddWorkflowPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Workflow/addWorkflow")
);
export const SuperAdminMailConfigPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/MailConfig/mailConfig")
);
export const SuperAdminAddMailConfigPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/MailConfig/addMailConfig")
);
export const SuperAdminWhatsappConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/WhatsappConfig/whatsappConfig"
  )
);
export const SuperAdminAddWhatsappConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/WhatsappConfig/addWhatsappConfig"
  )
);
export const SuperAdminTextMsgConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/TextMsgConfig/textMsgConfig"
  )
);
export const SuperAdminAddTextMsgConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/TextMsgConfig/addTextMsgConfig"
  )
);
export const SuperAdminDocumentConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/DocumentConfig/DocumentConfig"
  )
);
export const SuperAdminAddDocumentConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/DocumentConfig/addDocumentConfig"
  )
);
export const SuperAdminCreditAssessmentConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/CreditAssessmentConfig/CreditAssessmentConfig"
  )
);
export const SuperAdminAddCreditAssessmentConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/CreditAssessmentConfig/addCreditAssessmentConfig"
  )
);
export const SuperAdminLenderPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Lender/Lander")
);
export const SuperAdminLendersDetailPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Lender/LendersDetailPage")
);
export const SuperAdminAddLenderPersonalPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Lender/addPersonalInfo")
);
export const SuperAdminAddLenderAddressPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Lender/addAddressInfo")
);
export const SuperAdminAddLenderBankPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Lender/addBankInfo")
);
export const SuperAdminAddLenderDocumentPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Lender/AddDocumentInfo")
);
export const SuperAdminMailTemplateConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/MailTemplate/MailTemplateConfig"
  )
);
export const SuperAdminAddMailTemplateConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/MailTemplate/addMailTemplateConfig"
  )
);
export const SuperAdminWhatsappTemplateConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/WhatsappTemplate/WhatsappTemplateConfig"
  )
);
export const SuperAdminAddWhatsappTemplateConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/WhatsappTemplate/addWhatsappTemplateConfig"
  )
);
export const SuperAdminTextMsgTemplateConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/TextMsgTemplate/TextMsgTemplateConfig"
  )
);
export const SuperAdminAddTextMsgTemplateConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/TextMsgTemplate/addTextMsgTemplateConfig"
  )
);
export const SuperAdminCommunityPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Community/Community")
);
export const SuperAdminPayoutData = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Payout/payoutData")
);
export const SuperAdminCommunityDetailPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Community/CommunityDetailPage"
  )
);

export const SuperAdminAddCommunityPersonalPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Community/newAddPersonalInfo"
  )
);
export const SuperAdminAddCommunityProfessionalPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Community/AddProfessionalInfo"
  )
);
export const SuperAdminAddCommunityAddressPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/addAddressInfo")
);
export const SuperAdminAddCommunityBankPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/addBankInfo")
);
export const SuperAdminAddCommunityDocumentPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/AddDocumentInfo")
);
export const SuperAdminContactPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Contact/contactdetails")
);
export const SuperAdminRewardsConfig = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/RewardsConfig/RewardsConfig"
  )
);
export const SuperAdminAddRewardsConfig = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/RewardsConfig/addRewardsConfig"
  )
);
export const SuperAdminRewardsConfigTable = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/RewardsConfig/RewardsConfigTable"
  )
);

export const SuperAdminNbfcConfig = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/nbfc/NBFCConfig")
);
export const SuperAdminAddNbfcConfig = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/nbfc/addNBFCConfig")
);
export const SuperAdminAddBulkNbfc = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/nbfc/BulkAddNBFC")
);
export const SuperAdminNbfcInteraction = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/nbfc/newNbfcDetailsPage")
);

export const SuperAdminAddRM = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/RM/addRMDetails")
);
export const SuperAdminRMConfig = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/RM/rmDetails")
);

export const SuperAdminEnterpriseConfig = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/InvoiceFinance/EnterpriseData"
  )
);
export const SuperAdminEnterpriseConfigAdd = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/InvoiceFinance/AddEnterpriseData"
  )
);
export const SuperAdminEnterpriseConfigBulkUpload = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/InvoiceFinance/BulkUploadEnterpriseData"
  )
);

export const SuperAdminEsignConfigPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/EsignConfig/EsignConfig")
);
export const SuperAdminAddEsignConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/EsignConfig/addEsignConfig"
  )
);
export const SuperAdminEnashConfigPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/EnashConfig/EnashConfig")
);
export const SuperAdminAddEnashConfigPage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/EnashConfig/addEnashConfig"
  )
);
export const SuperAdminStagePage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/StageConfig/Stage")
);
export const SuperAdminAddStagePage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/StageConfig/addStage")
);
export const SuperAdminDocTemplatePage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/DocTemplateConfig/DocTemplate"
  )
);
export const SuperAdminAddDocTemplatePage = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/DocTemplateConfig/addDocTemplate"
  )
);
export const SuperAdminDownloadSheetPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Reports/DownloadReport")
);
export const SuperAdminAllLeadsPage = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/AllLeads")
);
export const SuperAdminLeadDetailPage = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/LeadDetailNew")
);
export const SuperAdminLeadLeadEdit = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddUserDetail"
  )
);
export const SuperAdminAddLeadBusinessPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddBusinessDetail"
  )
);
export const SuperAdminAddLeadsalaryPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddSalariedDetail"
  )
);
export const SuperAdminAddLeadPropertyPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddPropertyDetail"
  )
);
export const SuperAdminAddLeadDirectorPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddDirectorDetail"
  )
);
export const SuperAdminAddLeadAddressPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddAddressDetail"
  )
);
export const SuperAdminAddLeadLoanPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddLoanDetail"
  )
);
export const SuperAdminAddLeadBankPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/LeadCreation/addBankInfo")
);
export const SuperAdminAddLeadDocumentPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddDocumentInfo"
  )
);

export const SuperAdminAllOpportunityPage = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/AllOpportunity")
);
export const SuperAdminOpportunityDetailPage = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/opportunityDetails")
);
export const SuperAdminOpportunityEdit = React.lazy(() =>
  import("../components/container/CommunityDashboard/Opportunity/AddUserDetail")
);
export const SuperAdminOpportunityBusinessPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddBusinessDetail"
  )
);
export const SuperAdminOpportunitysalaryPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddSalariedDetail"
  )
);
export const SuperAdminOpportunityPropertyPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddPropertyDetail"
  )
);
export const SuperAdminOpportunityDirectorPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddDirectorDetail"
  )
);
export const SuperAdminOpportunityAddressPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddAddressDetail"
  )
);
export const SuperAdminOpportunityLoanPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Opportunity/AddLoanDetail")
);
export const SuperAdminOpportunityBankPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Opportunity/addBankInfo")
);
export const SuperAdminOpportunityDocumentPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddDocumentInfo"
  )
);

export const SuperAdminAddNewsPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/News/addNews")
);
export const SuperAdminNewsPage = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/News/NewsConfig")
);
export const SuperAdminBanks = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Bank/allBankCards")
);

export const SuperAdminAddBlog = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Blog/AddBlog")
);
export const SuperAdminBlogConfig = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Blog/Listing")
);
export const SuperAdminBlogDetails = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Blog/BlogDetailPage")
);

export const SuperAdminAddPayload = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Payout/AddPayout")
);
export const SuperAdminPayloadConfig = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Payout/PayoutConfig")
);

export const ThirdPartyAddCrif = React.lazy(() =>
  import("../components/container/ThirdPartyIntegration/CRIF/AddCrif")
);
export const ThirdPartyCrifConfig = React.lazy(() =>
  import("../components/container/ThirdPartyIntegration/CRIF/CrifListing")
);

export const SuperAdminAddOpportunity = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/Opportunities/addOpportunities"
  )
);
export const SuperAdminOpportunityConfig = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/Opportunities/OpportunitiesConfig"
  )
);

export const SuperAdminQueries = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/query")
);

export const SuperAdminBankConfig = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Bank/bankConfig")
);
export const SuperAdminAddBankConfig = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Bank/addBankConfig")
);
export const SuperAdminBulkAddBankConfig = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Bank/bulkAddBankConfig")
);
export const SuperAdminBankInteraction = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Bank/bankDetailsPage")
);
export const SuperAdminKyc = React.lazy(() =>
  import("../components/container/ThirdPartyIntegration/KYC/mainPagekyc")
);
export const SuperAdminGST = React.lazy(() =>
  import("../components/container/ThirdPartyIntegration/GST/mainPageGst")
);
export const GSTAdminInfo = React.lazy(() =>
  import("../components/container/ThirdPartyIntegration/GST/GSTinfo")
);

export const KarzaAadhaarInfo = React.lazy(() =>
  import("../components/container/ThirdPartyIntegration/KYC/info")
);
export const KarzaPanInfo = React.lazy(() =>
  import("../components/container/ThirdPartyIntegration/KYC/panInfo")
);
export const SuperQrListing = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/QR Builder/listing")
);
export const SuperAdminAddQr = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/QR Builder/addQr")
);
export const SUPER_ADMIN_QR_DETAILS = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/QR Builder/qrDetailPage")
);
export const ADMIN_BULK_COMMUNICATION_HISTORY = React.lazy(() =>
  import("../components/container/BulkCommunication/AllCommunication")
);
export const AdminCalculator = React.lazy(() =>
  import("../components/container/LenderDashboard/Calulator/caluculatorNew")
);
export const SUPER_ADMIN_PRODUCTIVITY = React.lazy(() =>
  import("../components/container/Productivity/layout")
);
export const SUPER_ADMIN_ROADMAP = React.lazy(() =>
  import("../components/container/Productivity/roadmap")
);

//Enterprise data

// community dashboard
export const CommunityLoginPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/DahsboardLayout/Login")
);
export const CommunityDashboardLayoutPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/DahsboardLayout/Dashboard.container"
  )
);
export const CommunityDashboardPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Dashboard/Dashboard")
);
export const CommunityAllLeadsPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/LeadCreation/AllLeads")
);
export const CommunityAddLeadPersonalPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddUserDetail"
  )
);
export const CommunityAddLeadBusinessPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddBusinessDetail"
  )
);
export const CommunityAddLeadsalaryPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddSalariedDetail"
  )
);
export const CommunityAddLeadPropertyPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddPropertyDetail"
  )
);
export const CommunityAddLeadDirectorPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddDirectorDetail"
  )
);
export const CommunityAddLeadAddressPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddAddressDetail"
  )
);
export const CommunityAddLeadLoanPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddLoanDetail"
  )
);
export const CommunityAddLeadBankPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/LeadCreation/addBankInfo")
);
export const CommunityAddLeadDocumentPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/AddDocumentInfo"
  )
);
export const CommunityLeadDetailPage = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/LeadDetailNew")
);
export const CommunityCommunityPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/Community")
);
export const CommunityCommunityDetailPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Community/CommunityDetailPage"
  )
);
export const CommunityAddCommunityPersonalPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Community/newAddPersonalInfo"
  )
);
export const CommunityAddCommunityProfessionalPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Community/AddProfessionalInfo"
  )
);
export const CommunityAddCommunityAddressPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/addAddressInfo")
);
export const CommunityAddCommunityBankPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/addBankInfo")
);
export const CommunityAddCommunityDocumentPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/AddDocumentInfo")
);
export const CommunityProfileDetails = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/profile")
);
export const CommunityQueries = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/query")
);
export const CommunityProfileDetailsEdit = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/editprofile")
);
export const CommunityAssociatePage = React.lazy(() =>
  import("../components/container/CommunityDashboard/associates/Associates")
);

export const CommunityPCMPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/pottentialCommunityMember/PottentialCommunityMember"
  )
);
export const CommunityAddPCMPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/pottentialCommunityMember/AddPCM"
  )
);
export const CommunityLedgerLeads = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/LeadCreation/LedgerLeadsTable"
  )
);
export const CommunityGenerateReport = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/GenerateReport/generateReport.container"
  )
);
export const CommunityForumsCreate = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/ForumsSection/createForums"
  )
);
export const CommunityAllForums = React.lazy(() =>
  import("../components/container/CommunityDashboard/ForumsSection/allForums")
);
export const CommunityForumDetail = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/ForumsSection/newForumDetails"
  )
);
export const CommunityForumForAll = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/ForumsSection/forumsForAll"
  )
);
export const CommunityCalculator = React.lazy(() =>
  import("../components/container/LenderDashboard/Calulator/caluculatorNew")
);
export const CommunityAllOpportunityPage = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/AllOpportunity")
);
export const CommunityOpportunityDetailPage = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/opportunityDetails")
);
export const CommunityOpportunityEdit = React.lazy(() =>
  import("../components/container/CommunityDashboard/Opportunity/AddUserDetail")
);
export const CommunityOpportunityBusinessPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddBusinessDetail"
  )
);
export const CommunityOpportunitysalaryPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddSalariedDetail"
  )
);
export const CommunityOpportunityPropertyPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddPropertyDetail"
  )
);
export const CommunityOpportunityDirectorPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddDirectorDetail"
  )
);
export const CommunityOpportunityAddressPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddAddressDetail"
  )
);
export const CommunityOpportunityLoanPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Opportunity/AddLoanDetail")
);
export const CommunityOpportunityBankPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Opportunity/addBankInfo")
);
export const CommunityOpportunityDocumentPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Opportunity/AddDocumentInfo"
  )
);
export const CommunityAddOpportunity = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/Opportunities/addOpportunities"
  )
);

// lender dashboard
export const LenderLoginPage = React.lazy(() =>
  import("../components/container/LenderDashboard/DahsboardLayout/Login")
);
export const LenderDashboardLayoutPage = React.lazy(() =>
  import(
    "../components/container/LenderDashboard/DahsboardLayout/Dashboard.container"
  )
);
export const LenderDashboardPage = React.lazy(() =>
  import("../components/container/LenderDashboard/Dashboard/lenderDashboard")
);
export const LenderAllLeadsPage = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/AllLeads")
);
export const LenderLeadDetailPage = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/LeadDetailNew")
);
export const addEmployeePage = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/Employees/addEmployee")
);
export const employeePage = React.lazy(() =>
  import(
    "../components/container/LenderDashboard/Leads/Employees/employeeDetails"
  )
);
export const LenderGenerateReport = React.lazy(() =>
  import(
    "../components/container/LenderDashboard/GenerateReport/generateReport.container"
  )
);
export const LenderCalculator = React.lazy(() =>
  import("../components/container/LenderDashboard/Calulator/caluculatorNew")
);
export const LenderIncommingleads = React.lazy(() =>
  import("../components/container/LenderDashboard/Leads/IncomingLeads")
);

// bulk communication
export const BulkCommunicationPage = React.lazy(() =>
  import("../components/container/BulkCommunication/Communication")
);
//CRIF

export const CrifPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/LeadCreation/crifDetails")
);
export const BulkCommunicationHistoryPage = React.lazy(() =>
  import("../components/container/BulkCommunication/AllCommunication")
);

// external community create
export const ExternalCommunityLayoutPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/ExternalCommunityLayout")
);
export const ExternalAddCommunityPersonalPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Community/newAddPersonalInfo"
  )
);
export const ExternalAddCommunityProfessionalPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Community/AddProfessionalInfo"
  )
);
export const ExternalAddCommunityAddressPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/addAddressInfo")
);
export const ExternalAddCommunityBankPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/addBankInfo")
);
export const ExternalAddCommunityDocumentPage = React.lazy(() =>
  import("../components/container/CommunityDashboard/Community/AddDocumentInfo")
);
export const ExternalUploadDocumentPage = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/Community/ExternalDocumentUpload"
  )
);

export const ExternalLeadsDetailsPage = React.lazy(() =>
  import("../components/container/ExternalPages/LeadsPublicLink")
);
export const ExternalLoanDetails = React.lazy(() =>
  import("../components/container/ExternalPages/LoanLead/CompleteLoanDetails")
);

export const Finbit_Page = React.lazy(() =>
  import("../components/container/Fibit/finbit.container")
);
export const ResetPassword = React.lazy(() =>
  import(
    "../components/container/CommunityDashboard/DahsboardLayout/Resetpassword"
  )
);

//CHapters
export const ChapterConfig = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Chapters/Chapterconfig")
);
export const AddChapterConfig = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/Chapters/AddChapterConfig"
  )
);
export const ChapterInfo = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/Chapters/ChapterInfo")
);
export const RMiClick = React.lazy(() =>
  import("../components/container/SuperAdminDashboard/RM/RMiClick")
);

export const TestAudio = React.lazy(() =>
  import("../components/container/Audio")
);
export const SUPER_ADMIN_CHAPTER_DASHBOARD = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/Chapters/chapterdashbaordAdmin"
  )
);
export const SUPER_ADMIN_CHAPTER_PARTICULAR_CHAPTER = React.lazy(() =>
  import(
    "../components/container/SuperAdminDashboard/Chapters/dashboardforparticularchapter/dashboardforparticular"
  )
);
