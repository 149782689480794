const routes = {
  // website
  WEBSITE_HOME: "/",
  WEBSITE_LENDERS: "/home/lenders",
  WEBSITE_COMMUNITY: "/home/community",
  WEBSITE_MERCHANT: "/home/merchant",
  WEBSITE_ABOUT: "/home/aboutus",
  WEBSITE_CONTACTUS: "/home/contactus",
  WEBSITE_DIGITAL_ONBOARDING: "/home/digitalonboarding",
  WEBSITE_DEBT_COLLECTION: "/home/debtcollection",
  WEBSITE_LOAN_MANAGEMENT_SYSTEM: "/home/loanmanagementsystem",
  WEBSITE_WORKFLOW_AUTOMATIONS: "/home/workflowautomations",
  WEBSITE_ESIGN_AND_EMANDATE_SOLUTIONS: "/home/esignandemandatesolutions",
  WEBSITE_BUSINESS_INTELLIGENCE_SUITE: "/home/businessintelligencesuite",
  WEBSITE_CORRESPONDENT_LENDING_SOLUTIONS:
    "/home/correspondentlendingsolutions",
  WEBSITE_LOAN_ORIGINATION: "/home/loanorigination",
  WEBSITE_FRAUD_PREVENTION: "/home/fraudprevention",
  WEBSITE_UNDERWRITTING: "/home/automatedunderwritting",
  WEBSITE_SERVICING: "/home/loanservicing",
  WEBSITE_RISKMANAGEMENT: "/home/riskmanagement",
  WEBSITE_FINTECH: "/home/fintech",
  WEBSITE_MICRO: "/home/microfinancing",
  WEBSITE_NBFC: "/home/nbfc",
  WEBSITE_NEO_BANKING: "/home/neobanking",
  WEBSITE_SOLE_LENDERS: "/home/solelenders",
  WEBSITE_STARTUP: "/home/startup",
  WEBSITE_MERCHANT_CASH: "/home/merchantcash",
  WEBSITE_PEER: "/home/peertopeer",
  WEBSITE_KYC: "/home/ekyc",
  WEBSITE_CIBIL: "/home/cibil",
  WEBSITE_BANK_STATEMENT_ANALYSIS: "/home/bankstatementsnalysis",
  WEBSITE_COMMUNICATION_VIA_WHATSAPP: "/home/communicationviawhatsapp",
  WEBSITE_IVR_INTEGRATION: "/home/ivrintegration",
  WEBSITE_PRODUCT_CONFIGURATION: "/home/productconfiguration",
  WEBSITE_LEAD_MANAGEMENT: "/home/leadmanagement",
  WEBSITE_CAPITIVE_FINANCE: "/home/capitivefinancing",
  WEBSITE_INVOICE_FINANCE: "/home/invoicefinance",
  WEBSITE_TRAVEL_AGENCIES: "/home/travelagencies",
  WEBSITE_POS_LENDING: "/home/poslending",
  WEBSITE_RESTURANTS_HOTEL: "/home/resturantshotel",
  WEBSITE_RETAIL: "/home/retail",
  WEBSITE_MEDICAL: "/home/medical",
  WEBSITE_BNPL: "/home/bnpl",
  WEBSITE_ECOMMERCE_FINANCING: "/home/ecommercefinancing",
  WEBSITE_AUTO_FINANCING: "/home/autofinancing",
  WEBSITE_EDUCATION_LOAN: "/home/educationloan",
  WEBSITE_OVERDRAFT_FINANCING: "/home/overdraftfinancing",
  WEBSITE_PRIVACY_POLICY: "/home/privacypolicy",
  WEBSITE_DEMO: "/demo/:userId",
  WEBSITE_LOAN_APPLY: "/home/applyLoan",
  WEBSITE_HOME_LOAN: "/home/homeloan",
  WEBSITE_LAP_LOAN: "/home/laploan",
  WEBSITE_BUSINESS_LOAN: "/home/buisinessloan",
  WEBSITE_OVERDRAFT_LOAN: "/home/overdraftloan",
  WEBSITE_PERSONAL_LOAN: "/home/personalloan",
  WEBSITE_SHORT_TERM_LOAN: "/home/shorttermloan",
  WEBSITE_LOCATE_US: "/home/locateus",
  WEBSITE_SOLUTIONS: "/home/oursolutions",
  WEBSITE_MARKET_PLACE: "/home/marketplace",
  WEBSITE_BORROWERS: "/home/borrowers",
  WEBSITE_VEHICLE: "/home/vehicleloan",
  WEBSITE_BANKPAGE: "/home/bank",
  WEBSITE_BANKPAGE_DETAILS: "/home/bankdetails/:nbfcId",
  WEBSITE_PRODUCT_DETAILS: "/home/productDetails/:nbfcId/:loanType",
  WEBSITE_BUSINESS: "/home/credhubbusiness",
  WEBSITE_RETAIL_NEW: "/home/credhubretail",
  WEBSITE_INFRA: "/home/infra",
  WEBSITE_MOBILITY: "/home/credhubmobility",
  WEBSITE_INVOICEFINANCE: "/home/invoicefinancing",
  WEBSITE_INVOICEFACTORING: "/home/invoicefactoring",
  WEBSITE_INVOICEDISCOUNTING: "/home/invoicediscounting",
  WEBSITE_EVLOAN: "/home/evloan",
  WEBSITE_WORK_CAPITAL_LOAN: "/home/workcapitalloan",
  WEBSITE_PLANT_MACHINARY: "/home/plantandmachinary",
  WEBSITE_MARKET_PLACE_NEW: "/home/marketplace",
  WEBSITE_TRADE_FINANCE: "/home/tradefinance",
  WEBSITE_LETTER_OF_CREDIT: "/home/letterofcredit",
  WEBSITE_BANK_GUARANTEE: "/home/bankguarantee",
  WEBSITE_Bill_DISCOUNTING: "/home/billdiscounting",
  WEBSITE_PROJECT_APPROVAL: "/home/projectapproval",
  WEBSITE_PROJECT_FINANCING: "/home/projectfinancing",
  WEBSITE_B2C: "/home/b2cfinance",
  WEBSITE_SUPPORT: "/home/support",
  // WEBSITE_MERCHANT_NEW:'/home/merchant',

  CHAT: "/chat",
  //ESIGN AND ENACH//
  ESIGN: "/esign/:loanId",
  ENACH: "/enach/:loanId",
  // super admin dashboard
  SUPER_ADMIN_LOGIN: "/admin/login",
  SUPER_ADMIN_DASHBOARD: "/admin/dashboard",
  SUPER_ADMIN_PRODUCT: "/admin/product",
  SUPER_ADMIN_ADD_PRODUCT: "/admin/addProduct",
  SUPER_ADMIN_MODULE: "/admin/module",
  SUPER_ADMIN_ADD_MODULE: "/admin/addModule",
  SUPER_ADMIN_ROLES: "/admin/roles",
  SUPER_ADMIN_ADD_ROLES: "/admin/addRole",
  SUPER_ADMIN_LICENCE: "/admin/licence",
  SUPER_ADMIN_ADD_LICENCE: "/admin/addLicence",
  SUPER_ADMIN_USER_MANAGEMENT: "/admin/userManagement",
  SUPER_ADMIN_ADD_USER: "/admin/addUser",
  SUPER_ADMIN_GROUP_MANAGEMENT: "/admin/groupManagement",
  SUPER_ADMIN_WORKFLOW: "/admin/workflow",
  SUPER_ADMIN_ADD_WORKFLOW: "/admin/addWorkflow",
  SUPER_ADMIN_MAIL_CONFIG: "/admin/mailConfig",
  SUPER_ADMIN_ADD_MAIL_CONFIG: "/admin/addMailConfig",
  SUPER_ADMIN_WHATSAPP_CONFIG: "/admin/whatsappConfig",
  SUPER_ADMIN_ADD_WHATSAPP_CONFIG: "/admin/addWhatsappConfig",
  SUPER_ADMIN_TEXT_MSG_CONFIG: "/admin/textMsgConfig",
  SUPER_ADMIN_ADD_TEXT_MSG_CONFIG: "/admin/addTextMsgConfig",
  SUPER_ADMIN_DOCUMENT_CONFIG: "/admin/documentConfig",
  SUPER_ADMIN_ADD_DOCUMENT_CONFIG: "/admin/addDocumentConfig",
  SUPER_ADMIN_CREDIT_ASSESSMENT_CONFIG: "/admin/creditAssessmentConfig",
  SUPER_ADMIN_ADD_CREDIT_ASSESSMENT_CONFIG: "/admin/addCreditAssessmentConfig",
  SUPER_ADMIN_LENDER_PAGE: "/admin/lenders",
  SUPER_ADMIN_LENDER_DETAIL_PAGE: "/admin/lendersDetail",
  SUPER_ADMIN_ADD_LENDER_PERSONAL: "/admin/addLenderPersonal",
  SUPER_ADMIN_ADD_LENDER_ADDRESS: "/admin/addLenderAddress",
  SUPER_ADMIN_ADD_LENDER_BANK: "/admin/addLenderBank",
  SUPER_ADMIN_ADD_LENDER_DOCUMENT: "/admin/addLenderDocument",
  SUPER_ADMIN_MAIL_TEMPLATE_CONFIG: "/admin/mailTemplateConfig",
  SUPER_ADMIN_ADD_MAIL_TEMPLATE_CONFIG: "/admin/addMailTemplateConfig",
  SUPER_ADMIN_WHATSAPP_TEMPLATE_CONFIG: "/admin/whatsappTemplateConfig",
  SUPER_ADMIN_ADD_WHATSAPP_TEMPLATE_CONFIG: "/admin/addWhatsappTemplateConfig",
  SUPER_ADMIN_TEXT_MSG_TEMPLATE_CONFIG: "/admin/textMsgTemplateConfig",
  SUPER_ADMIN_ADD_TEXT_MSG_TEMPLATE_CONFIG: "/admin/addTextMsgTemplateConfig",
  SUPER_ADMIN_COMMUNITY_PAGE: "/admin/community",
  SUPER_ADMIN_ASSOCIATES_PAGE: "/admin/associates",
  SUPER_ADMIN_COMMUNITY_ASSOCIATES_PAGE: "/admin/communityAssociates",
  SUPER_ADMIN_POTTENTIAL_COMMUNITY_ASSOCIATES_PAGE:
    "/admin/pottentialCommunityAssociates",
  SUPER_ADMIN_COMMUNITY_DETAIL_PAGE: "/admin/communityDetail",
  SUPER_ADMIN_ADD_COMMUNITY_PERSONAL: "/admin/addCommunityPersonal",
  SUPER_ADMIN_ADD_COMMUNITY_PROFESSIONAL: "/admin/addCommunityProfessional",
  SUPER_ADMIN_ADD_COMMUNITY_ADDRESS: "/admin/addCommunityAddress",
  SUPER_ADMIN_ADD_COMMUNITY_BANK: "/admin/addCommunityBank",
  SUPER_ADMIN_ADD_COMMUNITY_DOCUMENT: "/admin/addCommunityDocument",
  SUPER_ADMIN_CONTACT_PAGE: "/admin/contact",
  SUPER_ADMIN_ESIGN_CONFIG: "/admin/esignConfig",
  SUPER_ADMIN_ADD_ESIGN_CONFIG: "/admin/addEsignConfig",
  SUPER_ADMIN_ENASH_CONFIG: "/admin/enachConfig",
  SUPER_ADMIN_ADD_REWARDS_CONFIG: "/admin/addRewardsConfig",
  SUPER_ADMIN_REWARDS_CONFIG: "/admin/rewardsConfig",
  SUPER_ADMIN_REWARDS_CONFIG_TABLE: "/admin/rewardsConfigTable",
  SUPER_ADMIN_COMMUNITY_PCM: "/admin/pcm",
  SUPER_ADMIN_COMMUNITY_ADD_PCM: "/admin/add/pcm",

  SUPER_ADMIN_NBFC: "/admin/nbfcConfig",
  SUPER_ADMIN_BULK_NBFC: "/admin/nbfcBulk",
  SUPER_ADMIN_ADD_NBFC: "/admin/addNbfc",
  SUPER_ADMIN_NBFC_INTERACTION: "/admin/nbfcInteraction",

  SUPER_ADMIN_ADD_BANK_CONFIG: "/admin/addBankConfig",
  SUPER_ADMIN_BANK_CONFIG: "/admin/bankConfig",
  SUPER_ADMIN_BANK_INTERACTION: "/admin/bankInteraction",
  SUPER_ADMIN_BULK_ADD_BANK_CONFIG: "/admin/bulkAddBankConfig",

  SUPER_ADMIN_ADD_NEWS: "/admin/addNews",
  SUPER_ADMIN_NEWS: "/admin/news",
  SUPER_ADMIN_BANK_CARDS: "/admin/banks",

  SUPER_ADMIN_ADD_ENASH_CONFIG: "/admin/addEnachConfig",
  SUPER_ADMIN_STAGE: "/admin/stage",
  SUPER_ADMIN_ADD_STAGE: "/admin/addStage",
  SUPER_ADMIN_DOC_TEMPLATE: "/admin/docTemplate",
  SUPER_ADMIN_ADD_DOC_TEMPLATE: "/admin/addDocTemplate",
  SUPER_ADMIN_DOWNLOAD_SHEET: "/admin/downloadSheet",
  SUPER_ADMIN_ALL_LEADS: "/admin/leads",
  SUPER_ADMIN_LEAD_DETAIL: "/admin/leadDetail",

  SUPER_ADMIN_ADD_BLOG: "/admin/add/blog",
  SUPER_ADMIN_BLOG_CONFIG: "/admin/blog/config",
  SUPER_ADMIN_BLOG_DETAILS: "/admin/blog/details",

  SUPER_ADMIN_ADD_RM_DETAILS: "/admin/add/rm/config",
  SUPER_ADMIN_RM_CONFIG: "/admin/rm/config",

  SUPER_ADMIN_OPPORTUNITIES: "/admin/opportunities",
  SUPER_ADMIN_ADD_OPPORTUNITIES: "/admin/add/opportunities",
  SUPER_ADMIN_OPPORTUNITIES_DETAILS: "/admin/OpportunityDetails",
  ADMIN_OPPORTUNITY_PERSONAL: "/admin/addOpportunityPersonal",
  ADMIN_OPPORTUNITY_BUSINESS: "/admin/addOpportunityBusiness",
  ADMIN_OPPORTUNITY_SALARIED: "/admin/addOpportunitySalaried",
  ADMIN_OPPORTUNITY_PROPERTY: "/admin/addOpportunityProperty",
  ADMIN_OPPORTUNITY_DIRECTOR: "/admin/addOpportunityDirector",
  ADMIN_OPPORTUNITY_ADDRESS: "/admin/addOpportunityAddress",
  ADMIN_OPPORTUNITY_LOAN: "/admin/addOpportunityLoan",
  ADMIN_OPPORTUNITY_BANK: "/admin/addOpportunityBank",
  ADMIN_OPPORTUNITY_DOCUMENT: "/admin/addOpportunityDocument",

  ADMIN_ADD_LEAD_PERSONAL: "/admin/addLeadPersonal",
  ADMIN_ADD_LEAD_BUSINESS: "/admin/addLeadBusiness",
  ADMIN_ADD_LEAD_SALARIED: "/admin/addLeadSalaried",
  ADMIN_ADD_LEAD_PROPERTY: "/admin/addLeadProperty",
  ADMIN_ADD_LEAD_DIRECTOR: "/admin/addLeadDirector",
  ADMIN_ADD_LEAD_ADDRESS: "/admin/addLeadAddress",
  ADMIN_ADD_LEAD_LOAN: "/admin/addLeadLoan",
  ADMIN_ADD_LEAD_BANK: "/admin/addLeadBank",
  ADMIN_ADD_LEAD_DOCUMENT: "/admin/addLeadDocument",
  ADMIN_BULK_COMMUNICATION: "/admin/bulkCommunication",
  ADMIN_CALCULATOR: "/admin/calculator",
  ADMIN_QUERY: "/admin/query",

  // community dashboard
  COMMUNITY_LOGIN: "/community/login",
  COMMUNITY_DASHBOARD: "/community/dashboard",
  COMMUNITY_ALL_LEADS: "/community/leads",
  COMMUNITY_ADD_LEAD_PERSONAL: "/community/addLeadPersonal",
  COMMUNITY_ADD_LEAD_BUSINESS: "/community/addLeadBusiness",
  COMMUNITY_ADD_LEAD_SALARIED: "/community/addLeadSalaried",
  COMMUNITY_ADD_LEAD_PROPERTY: "/community/addLeadProperty",
  COMMUNITY_ADD_LEAD_DIRECTOR: "/community/addLeadDirector",
  COMMUNITY_ADD_LEAD_ADDRESS: "/community/addLeadAddress",
  COMMUNITY_ADD_LEAD_LOAN: "/community/addLeadLoan",
  COMMUNITY_ADD_LEAD_BANK: "/community/addLeadBank",
  COMMUNITY_ADD_LEAD_DOCUMENT: "/community/addLeadDocument",
  COMMUNITY_LEAD_DETAIL: "/community/leadDetail",
  COMMUNITY_BULK_COMMUNICATION: "/community/bulkCommunication",
  COMMUNITY_COMMUNITY_PAGE: "/community/community",
  COMMUNITY_COMMUNITY_DETAIL_PAGE: "/community/communityDetail",
  COMMUNITY_ADD_COMMUNITY_PERSONAL: "/community/addCommunityPersonal",
  COMMUNITY_ADD_COMMUNITY_PROFESSIONAL: "/community/addCommunityProfessional",
  COMMUNITY_ADD_COMMUNITY_ADDRESS: "/community/addCommunityAddress",
  COMMUNITY_ADD_COMMUNITY_BANK: "/community/addCommunityBank",
  COMMUNITY_ADD_COMMUNITY_DOCUMENT: "/community/addCommunityDocument",
  COMMUNITY_BULK_COMMUNICATION_HISTORY: "/community/bulkCommunicationHistory",
  COMMUNITY_PROFILE_DETAILS: "/community/profile",
  COMMUNITY_QUERY: "/community/query",
  EDIT_PROFILE: "/community/editprofile",
  COMMUNITY_LEDGER_LEADS: "/community/ledger-leads",
  COMMUNITY_GENERATE_REPORT: "/community/generateReport",
  COMMUNITY_FORUMS: "/community/addforums",
  COMMUNITY_ALL_FORUMS: "/community/allforums",
  FORUM_DETAILS_PAGE: "/community/forumdetails",
  COMMUNITY_FORUMS_FOR_ALL: "/community/forums",
  COMMUNITY_CALCULATOR: "/community/calculator",
  COMMUNITY_OPPORTUNITIES: "/community/opportunities",
  COMMUNITY_ADD_OPPORTUNITIES: "/community/add/opportunities",
  COMMUNITY_OPPORTUNITIES_DETAILS: "/community/OpportunityDetails",
  COMMUNITY_OPPORTUNITY_PERSONAL: "/community/addOpportunityPersonal",
  COMMUNITY_OPPORTUNITY_BUSINESS: "/community/addOpportunityBusiness",
  COMMUNITY_OPPORTUNITY_SALARIED: "/community/addOpportunitySalaried",
  COMMUNITY_OPPORTUNITY_PROPERTY: "/community/addOpportunityProperty",
  COMMUNITY_OPPORTUNITY_DIRECTOR: "/community/addOpportunityDirector",
  COMMUNITY_OPPORTUNITY_ADDRESS: "/community/addOpportunityAddress",
  COMMUNITY_OPPORTUNITY_LOAN: "/community/addOpportunityLoan",
  COMMUNITY_OPPORTUNITY_BANK: "/community/addOpportunityBank",
  COMMUNITY_OPPORTUNITY_DOCUMENT: "/community/addOpportunityDocument",
  // lender dashboard
  LENDER_LOGIN: "/lender/login",
  LENDER_DASHBOARD: "/lender/dashboard",
  LENDER_ALL_LEADS: "/lender/leads",
  LENDER_LEAD_DETAIL: "/lender/leadDetail",
  LENDER_BULK_COMMUNICATION: "/lender/bulkCommunication",
  LENDER_ADD_EMPLOYEE: "/lender/addEmployee",
  LENDER_EMPLOYEE_LIST: "/lender/employees",
  LENDER_GENERATE_REPORT: "/lender/generateReport",
  LENDER_CALCULATOR: "/lender/calculator",
  LENDER_INCOMING_LEADS: "/lender/incommingleads",

  //Crif
  CRIF: "/crif",
  LENDER_BULK_COMMUNICATION_HISTORY: "/lender/bulkCommunicationHistory",

  // external community create
  EXTERNAL_ADD_COMMUNITY_PERSONAL:
    "/external/addCommunityPersonal/:externalCommunityId",
  EXTERNAL_ADD_COMMUNITY_PROFESSIONAL:
    "/external/addCommunityProfessional/:externalCommunityId",
  EXTERNAL_ADD_COMMUNITY_ADDRESS:
    "/external/addCommunityAddress/:externalCommunityId",
  EXTERNAL_ADD_COMMUNITY_BANK:
    "/external/addCommunityBank/:externalCommunityId",
  EXTERNAL_ADD_COMMUNITY_DOCUMENT:
    "/external/addCommunityDocument/:externalCommunityId",
  EXTERNAL_UPLOAD_ONLY_DOCUMENTS_COMMUNITY:
    "/external/uploadDocuments/:externalCommunityId",

  //Lead Details
  EXTERNAL_LEADS_DETAILS: "/external/loan/details/:userId/:linkId",

  //External Loan complete
  EXTERNAL_ADD_LOAN_DETAILS: "/external/add/loan/details/:userId/:loanId",
  EXTERNAL_COMMUNITY_ADD_LEAD_PERSONAL: "/external/community/addLeadPersonal",
  EXTERNAL_COMMUNITY_ADD_LEAD_BUSINESS: "/external/community/addLeadBusiness",
  EXTERNAL_COMMUNITY_ADD_LEAD_SALARIED: "/external/community/addLeadSalaried",
  EXTERNAL_COMMUNITY_ADD_LEAD_PROPERTY: "/external/community/addLeadProperty",
  EXTERNAL_COMMUNITY_ADD_LEAD_DIRECTOR: "/external/community/addLeadDirector",
  EXTERNAL_COMMUNITY_ADD_LEAD_ADDRESS: "/external/community/addLeadAddress",
  EXTERNAL_COMMUNITY_ADD_LEAD_DOCUMENT: "/external/community/addLeadDocument",
  EXTERNAL_COMMUNITY_ADD_LEAD_LOAN: "/external/community/addLeadLoan",

  RESET_PASSWORD: "/resetpassword",

  FINBIT: "/finbit",

  THIRD_PARTY_KYC_ADMIN: "/admin/kyc",

  THIRD_PARTY_ADD_CRIF: "/manual/crif/add",
  THIRD_PARTY_CRIF_DETAILS: "/manual/crif/details",
  KARZA_AADHAAR_INFO: "/admin/info",
  KARZA_PAN_INFO: "/admin/panInfo",
  GST_PORTAL_ADMIN: "/admin/gst",
  GST_ADMIN_INFO: "/admin/gstInfo",
  SUPER_ADMIN_QR_LISTING: "/admin/qrlisting",
  SUPER_ADMIN_ADD_QR: "/admin/addqr",
  SUPER_ADMIN_QR_DETAILS: "/admin/qrdetails",
  ADMIN_BULK_COMMUNICATION_HISTORY: "/admin/bulkCommunicationHistory",

  //CHAPTERS
  SUPER_ADMIN_ADD_CHAPTER_CONFIG: "/admin/add/chapter",
  SUPER_ADMIN_CHAPTER_CONFIG: "/admin/chapter",
  SUPER_ADMIN_RM_INFO: "/admin/rm/info",
  SUPER_ADMIN_CHAPTER_CONFIG_DETAILS: "/admin/chapter/details",
  SUPER_ADMIN_PRODUCTIVITY: "/admin/productivity",
  SUPER_ADMIN_ROADMAP: "/admin/roadmap",

  // Enterprise Data
  SUPER_ADMIN_ENTERPRISE_DATA_CONFIG: "/admin/enterprise/config",
  SUPER_ADMIN_ENTERPRISE_DATA_BULK_UPLOAD: "/admin/enterprise/bulk/upload",
  SUPER_ADMIN_ENTERPRISE_DATA_ADD: "/admin/enterprise/config/add",

  SUPER_ADMIN_ADD_PAYLOAD: "/admin/add/payout",
  SUPER_ADMIN_PAYLOAD_CONFIG: "/admin/payout/config",
  SUPER_ADMIN_PAYOUT_DATA: "/admin/payout/payoutData",

  TEST_AUDIO: "/testAudio",
  SUPER_ADMIN_CHAPTER_DASHBOARD: "/admin/chapterdashboard",
  SUPER_ADMIN_CHAPTER_PARTICULAR_CHAPTER: "/admin/dashboardforparticular",
};

export default routes;
